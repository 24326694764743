<template>
  <li
    class="CheckOptionItem"
    :class="itemClass"
  >
    <label>
      <ContextMenu
        v-if="option.disabled && option.disabledTitle"
        :use-hover="true"
        :arrow-offset="15"
        :x-offset="-12"
        :y-offset="10"
      >
        <template v-slot:button>
          <input
            type="checkbox"
            :disabled="true"
          >
        </template>
        <template v-slot:default>
          <div class="contextMenuDisabledTitle">
            {{ option.disabledTitle }}
          </div>
        </template>
      </ContextMenu>

      <input
        v-else
        v-model="value"
        type="checkbox"
        :value="option.value"
      >

      <span class="labelText">
        {{ option.label }}
      </span>

      <component
        :is="option.icon"
        v-if="option.icon"
        class="icon"
      />
    </label>
  </li>
</template>

<script>
import ContextMenu from './ContextMenu.vue'

export default {
  components: {
    ContextMenu
  },
  props: {
    model: {
      type: Object,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    option: {
      type: Object,
      required: true
    }
  },
  computed: {
    itemClass () {
      return {
        ...this.option.class,
        disabled: this.option.disabled
      }
    },
    value: {
      get () {
        return this.model[this.name]
      },
      set (value) {
        this.model[this.name] = value
      }
    }
  }
}
</script>

<style lang="scss">
li.CheckOptionItem {
  label {
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: 14px;
    text-transform: none;
    color: $gray-text;

    .ContextMenu {
      .buttonWrapper {
        height: 20px;
      }
    }

    .labelText {
      padding-left: 1rem;
    }
  }

  &.disabled {
    label {
      cursor: default;

      input {
        background-color: $gray-tertiary;
      }

      .labelText {
        color: $gray-sec;
      }
    }
  }
}

.context-menu-portal-target {
  .contextMenuDisabledTitle {
    padding: 2rem;
  }
}
</style>
