<template>
  <div class="GridOverlay">
    <Spinner class="stretch" />
  </div>
</template>

<script>
import Spinner from '../Spinner.vue'
export default {
  components: {
    Spinner
  }
}
</script>
