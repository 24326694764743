<template>
  <div class="CheckOptions">
    <h5 v-if="header.length">
      {{ header }}
    </h5>
    <ul>
      <CheckOptionItem
        v-for="option in options"
        :key="option.value"
        :option="option"
        :model="model"
        :name="name"
      />
    </ul>
  </div>
</template>

<script>
import CheckOptionItem from './CheckOptionItem.vue'

export default {
  components: {
    CheckOptionItem
  },
  props: {
    header: {
      type: String,
      default: ''
    },
    model: {
      type: Object,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    options: {
      type: Array,
      required: true
    }
  }
}
</script>

<style lang="scss">
.CheckOptions {
  ul {
    display: flex;
    flex-direction: column;
  }
}

.assignNoLineItemsWarning {
  padding: 2rem;
}
</style>
